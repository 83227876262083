/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  isLoading: false,
  questions: [],
  questionStatistics: {
    correctAnswersCount: 0,
    wrongAnswersCount: 0,
  },
  adminQuestions: [],
  adminAnswers: [],
  importResults: {
    results: [],
    errors: [],
  },
  sections: [],
  lockedSections: [],
  sources: [],
  questionsToVerifyStatistics: {
    data: [],
    total: 0,
  },
  questionToVerify: {
    question: {},
    total: 0,
  },
  loadings: {
    sources: false,
    sections: false,
    addToFavorites: false,
    dataFetching: false,
    questionStatistics: false,
  },
};

export const importQuestions = createAsyncThunk('questionDatabase/importQuestions', async file => {
  return API.QuestionsDatabaseService.importQuestions(file);
});

export const addQuestion = createAsyncThunk('questionDatabase/addQuestion', async question => {
  return API.QuestionsDatabaseService.addQuestion(question);
});

export const addAnswer = createAsyncThunk('questionDatabase/addAnswer', async ({ questionId, answer }) => {
  return API.QuestionsDatabaseService.addAnswer(questionId, answer);
});

export const editAnswer = createAsyncThunk('questionDatabase/editAnswer', async ({ answerId, answer }) => {
  return API.QuestionsDatabaseService.editAnswer(answerId, answer);
});

export const getAnswers = createAsyncThunk('questionDatabase/getAnswers', async questionId => {
  return API.QuestionsDatabaseService.getAnswers(questionId);
});

export const editQuestion = createAsyncThunk('questionDatabase/editQuestion', async ({ questionId, question }) => {
  return API.QuestionsDatabaseService.editQuestion(questionId, question);
});

export const deleteQuestion = createAsyncThunk('questionDatabase/deleteQuestion', async questionId => {
  return API.QuestionsDatabaseService.deleteQuestion(questionId);
});

export const addSection = createAsyncThunk('questionDatabase/addSection', async section => {
  return API.QuestionsDatabaseService.addSection(section);
});

export const addSource = createAsyncThunk('questionDatabase/addSource', async source => {
  return API.QuestionsDatabaseService.addSource(source);
});

export const getSections = createAsyncThunk('questionDatabase/getSections', async () => {
  return API.QuestionsDatabaseService.getSections();
});

export const getLockedSections = createAsyncThunk('questionDatabase/getLockedSections', async () => {
  return API.QuestionsDatabaseService.getLockedSections();
});

export const getSources = createAsyncThunk('questionDatabase/getSources', async () => {
  return API.QuestionsDatabaseService.getSources();
});

export const editSection = createAsyncThunk('questionDatabase/editSection', async ({ sectionId, section }) => {
  return API.QuestionsDatabaseService.editSection(sectionId, section);
});

export const editSource = createAsyncThunk('questionDatabase/editSource', async ({ sourceId, source }) => {
  return API.QuestionsDatabaseService.editSource(sourceId, source);
});

export const getAdminQuestions = createAsyncThunk(
  'questionDatabase/getAdminQuestions',
  async ({ page, limit, search, filter }) => {
    return API.QuestionsDatabaseService.getAdminQuestions({ page, limit, search, filter });
  },
);

export const addMultipleQuestions = createAsyncThunk('questionDatabase/addMultipleQuestions', async questions => {
  return API.QuestionsDatabaseService.addMultipleQuestions(questions);
});

export const getQuestionsToVerifyStatistics = createAsyncThunk(
  'questionDatabase/getQuestionsToVerifyStatistics',
  async () => {
    return API.QuestionsDatabaseService.getQuestionsToVerifyStatistics();
  },
);

export const convertQuestions = createAsyncThunk('questionDatabase/convertQuestions', async () => {
  return API.QuestionsDatabaseService.convertQuestions();
});

export const getQuestionToVerify = createAsyncThunk(
  'questionDatabase/getQuestionToVerify',
  async ({ section, source, difficulty }) => {
    return API.QuestionsDatabaseService.getQuestionToVerify(section, source, difficulty);
  },
);

export const verifyQuestion = createAsyncThunk(
  'questionDatabase/verifyQuestion',
  async ({ questionId, notes, status }) => {
    return API.QuestionsDatabaseService.verifyQuestion(questionId, notes, status);
  },
);

export const removeRequirement = createAsyncThunk(
  'questionDatabase/removeRequirement',
  async ({ sectionId, requirementId }) => {
    return API.QuestionsDatabaseService.removeRequirement(sectionId, requirementId);
  },
);

export const addSectionRequirement = createAsyncThunk(
  'questionDatabase/addSectionRequirement',
  async ({ sectionId, requirement }) => {
    return API.QuestionsDatabaseService.addSectionRequirement(sectionId, requirement);
  },
);

export const getQuestionsForStudent = createAsyncThunk(
  'questionDatabase/getQuestionsForStudent',
  async ({ sections, difficulties, sources, statuses, page, timestamp }) => {
    return API.QuestionsDatabaseService.getQuestionsForStudent({
      sections,
      difficulties,
      sources,
      statuses,
      page,
      timestamp,
    });
  },
);

export const getQuestionStatistics = createAsyncThunk(
  'questionDatabase/getQuestionStatistics',
  async ({ sections, difficulties, sources, statuses }) =>
    API.QuestionsDatabaseService.getQuestionStatistics({
      sections,
      difficulties,
      sources,
      statuses,
    }),
);

export const sendSelectedAnswer = createAsyncThunk(
  'questionDatabase/sendSelectedAnswer',
  async ({ questionId, answersIds }) => {
    return API.QuestionsDatabaseService.sendSelectedAnswer(questionId, answersIds);
  },
);

export const addQuestionToFavourites = createAsyncThunk(
  'questionDatabase/addQuestionToFavourites',
  async questionId => {
    return API.QuestionsDatabaseService.addQuestionToFavourites(questionId);
  },
);

const questionDatabaseSlice = createSlice({
  name: 'questionDatabase',
  initialState,
  reducers: {},
  extraReducers: {
    [addQuestion.fulfilled]: (state, action) => {
      state.questions = action.payload;
      notification.success({
        message: 'Success',
        description: 'Pomyślnie dodano pytanie',
      });
    },
    [addQuestion.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się dodać pytania',
      });
    },
    [getSections.fulfilled]: (state, action) => {
      state.sections = action.payload.data;
      state.loadings.sections = false;
    },
    [getSources.fulfilled]: (state, action) => {
      state.sources = action.payload.data;
      state.loadings.sources = false;
    },
    [getSections.rejected]: (state, action) => {
      state.loadings.sections = false;
      notification.error({
        message: 'Error',
        description: 'Nie udało się pobrać sekcji',
      });
    },
    [getSources.rejected]: (state, action) => {
      state.loadings.sources = false;
      notification.error({
        message: 'Error',
        description: 'Nie udało się pobrać źródeł',
      });
    },
    [getSections.pending]: (state, action) => {
      state.loadings.sections = true;
    },
    [getLockedSections.fulfilled]: (state, action) => {
      state.lockedSections = action.payload.data;
    },
    [getLockedSections.rejected]: (state, action) => {
      notification.error({
        message: 'Nie udało się pobrać zablokowanych sekcji',
      });
    },
    [getSources.pending]: (state, action) => {
      state.loadings.sources = true;
    },
    [addSection.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: 'Pomyślnie dodano sekcje',
      });
    },
    [addSection.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się dodać sekcji',
      });
    },
    [addSource.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: 'Pomyślnie dodano źródło',
      });
    },
    [addSource.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się dodać źródła',
      });
    },
    [editSection.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: 'Pomyślnie edytowano sekcje',
      });
    },
    [editSection.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się edytować sekcji',
      });
    },
    [editSource.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: 'Pomyślnie edytowano źródło',
      });
    },
    [editSource.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się edytować źródła',
      });
    },
    [getAdminQuestions.fulfilled]: (state, action) => {
      state.adminQuestions = action.payload.data;
      state.isLoading = false;
    },
    [getAdminQuestions.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się pobrać pytań',
      });
      state.isLoading = false;
    },
    [getAdminQuestions.pending]: (state, action) => {
      state.isLoading = true;
    },
    [editQuestion.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: 'Pomyślnie edytowano pytanie',
      });
    },
    [editQuestion.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się edytować pytania',
      });
    },
    [deleteQuestion.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: 'Pomyślnie usunięto pytanie',
      });
    },
    [deleteQuestion.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się usunąć pytania',
      });
    },
    [addAnswer.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: 'Pomyślnie dodano odpowiedź',
      });
    },
    [addAnswer.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się dodać odpowiedzi',
      });
    },
    [getAnswers.fulfilled]: (state, action) => {
      state.adminAnswers = action.payload.data;
    },
    [getAnswers.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się pobrać odpowiedzi',
      });
    },
    [getAnswers.pending]: (state, action) => {
      state.adminAnswers = [];
    },
    [editAnswer.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: 'Pomyślnie edytowano odpowiedź',
      });
    },
    [editAnswer.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się edytować odpowiedzi',
      });
    },
    [importQuestions.fulfilled]: (state, action) => {
      state.importResults = action.payload.data;
      state.isLoading = false;
      notification.success({
        message: 'Success',
        description: 'Pomyślnie przetworzono pytania',
      });
    },
    [importQuestions.rejected]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Error',
        description: 'Nie udało się przetworzyć pytań',
      });
    },
    [importQuestions.pending]: (state, action) => {
      state.importResults = {
        results: [],
        errors: [],
      };
      state.isLoading = true;
      notification.info({
        message: 'Info',
        description: 'Trwa przetwarzanie pliku',
      });
    },
    [addMultipleQuestions.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: `Pomyślnie dodano ${action.payload.data.numberOfQuestions} pytań`,
      });
    },
    [addMultipleQuestions.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się dodać pytań',
      });
    },
    [getQuestionsToVerifyStatistics.fulfilled]: (state, action) => {
      state.questionsToVerifyStatistics = action.payload.data;
      state.isLoading = false;
    },
    [getQuestionsToVerifyStatistics.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się pobrać statystyk',
      });
      state.isLoading = false;
    },
    [getQuestionsToVerifyStatistics.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getQuestionToVerify.fulfilled]: (state, action) => {
      state.questionToVerify = action.payload.data;
      state.isLoading = false;
    },
    [getQuestionToVerify.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się pobrać pytania',
      });
      state.isLoading = false;
      state.questionToVerify = {
        question: {},
        total: 0,
      };
    },
    [getQuestionToVerify.pending]: (state, action) => {
      state.isLoading = true;
    },
    [verifyQuestion.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: 'Pomyślnie zweryfikowano pytanie',
      });
    },
    [verifyQuestion.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się zweryfikować pytania',
      });
    },
    [convertQuestions.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: 'Pomyślnie przekonwertowano pytania',
      });
    },
    [convertQuestions.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się przekonwertować pytań',
      });
    },
    [addSectionRequirement.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: 'Pomyślnie dodano wymaganie',
      });
    },
    [addSectionRequirement.rejected]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać wymagania',
        description: action.error.message,
      });
    },
    [removeRequirement.fulfilled]: (state, action) => {
      notification.success({
        message: 'Success',
        description: 'Pomyślnie usunięto wymaganie',
      });
    },
    [removeRequirement.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się usunąć wymagania',
      });
    },
    [getQuestionsForStudent.fulfilled]: (state, action) => {
      state.questions = action.payload.data;
      state.isLoading = false;
      state.loadings.dataFetching = false;
    },
    [getQuestionsForStudent.rejected]: (state, action) => {
      notification.error({
        message: 'Error',
        description: 'Nie udało się pobrać pytań',
      });
      state.isLoading = false;
      state.loadings.dataFetching = false;
    },
    [getQuestionsForStudent.pending]: (state, action) => {
      state.isLoading = true;
      state.loadings.dataFetching = true;
    },
    [sendSelectedAnswer.fulfilled]: (state, action) => {
      // notification.success({
      //   message: 'Success',
      //   description: 'Pomyślnie wysłano odpowiedź',
      // });
    },
    [sendSelectedAnswer.rejected]: (state, action) => {
      notification.error({
        message: 'Nie udało się wysłać odpowiedzi',
      });
    },
    [addQuestionToFavourites.fulfilled]: (state, action) => {
      console.log(action.payload.data);
      if (action.payload.data.isFavorite) {
        notification.success({
          message: 'Pomyślnie dodano pytanie do ulubionych',
        });
      } else {
        notification.success({
          message: 'Pomyślnie usunięto pytanie z ulubionych',
        });
      }
      state.loadings.addToFavorites = false;
    },
    [addQuestionToFavourites.rejected]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać pytania do ulubionych',
      });
      state.loadings.addToFavorites = false;
    },
    [addQuestionToFavourites.pending]: (state, action) => {
      state.loadings.addToFavorites = true;
    },
    [getQuestionStatistics.fulfilled]: (state, action) => {
      state.questionStatistics = action.payload.data;
      state.loadings.questionStatistics = false;
    },
    [getQuestionStatistics.rejected]: (state, action) => {
      notification.error({
        message: 'Nie udało się pobrać statystyk',
      });
      state.loadings.questionStatistics = false;
    },
    [getQuestionStatistics.pending]: (state, action) => {
      state.loadings.questionStatistics = true;
      state.questionStatistics = {
        correctAnswersCount: 0,
        wrongAnswersCount: 0,
      };
    },
  },
});

export default questionDatabaseSlice.reducer;
